import StateMachine from 'javascript-state-machine/lib/state-machine.js';
import anime from 'animejs/lib/anime.es.js';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Utils from '../helpers/utils.js';

import GlideSlideshow from '../components/glide-slideshow.js';
import SpinesAnimationDesktop from '../components/spines-animation-desktop.js';
import SpinesAnimationMobile from '../components/spines-animation-mobile.js';


export default class StoriesController {
  constructor(options) {
    this.easing = 'easeInOutQuad';
    this.timing = 1500;

    this.spinesAnimationDesktop = new SpinesAnimationDesktop({easing: this.easing, timing: this.timing});
    this.spinesAnimationMobile = new SpinesAnimationMobile({easing: this.easing, timing: this.timing});

    this.spineAnimations = Utils.isMobileView() ? this.spinesAnimationMobile : this.spinesAnimationDesktop;

    this.initialState = 'menu';

    this.states = new StateMachine({
      init: this.initialState,
      transitions: [
        { name: 'goToMenu',                   from: '*', to: 'menu' },
        { name: 'goToStoryForeword',          from: '*', to: 'story-foreword' },
        { name: 'goToStoryRoKwon',           from: '*', to: 'story-ro-kwon' },
        { name: 'goToStoryOisinMckenna',     from: '*', to: 'story-oisin-mckenna' },
        { name: 'goToStoryBrontezPurnell',   from: '*', to: 'story-brontez-purnell' },
        { name: 'goToStoryIsleMcelroy',      from: '*', to: 'story-isle-mcelroy' },
        { name: 'goToStoryRoxaneGay',        from: '*', to: 'story-roxane-gay' },
        { name: 'goToStoryJohnPaulBrammer', from: '*', to: 'story-john-paul-brammer' },
        { name: 'goToStoryInfo',              from: '*', to: 'story-info' },
      ],
      data: {
        preserveScroll: false,
        scrollTriggering: false
      },
      methods: {
        
        /**
         * Transition methods
         */

        onGoToMenu: () => {
          // Do nothing
        },
        /**
         * Lifecycle methods
         */
        
        onEnterState: lifecycle => {
          document.body.setAttribute('data-current-state', lifecycle.to);
        },

        onEnterMenu: lifecycle => {
          // TODO: Maybe we should skipt the animation on first load, meaning
          // when the transition from is from no/from an undefined state (first load).

          // Animate the spines in
          this.spineAnimations = Utils.isMobileView() ? this.spinesAnimationMobile : this.spinesAnimationDesktop;
          this._animate('menu');

          document.querySelector(`[data-menu-icon]`).focus();
          if(this.states)
            this.states.scrollTriggering = false;
        },
        
        onLeaveMenu: lifecycle => {
          // Animate the spines out
          const animNum = {
            'story-foreword' : 'foreword',
            'story-ro-kwon' : '1',
            'story-oisin-mckenna' : '2',
            'story-brontez-purnell' : '3',
            'story-isle-mcelroy' : '4',
            'story-roxane-gay' : '5',
            'story-john-paul-brammer' : '6',
            'story-info' : 'info',
          }
          const num = animNum[lifecycle.to];
          this.spineAnimations = Utils.isMobileView() ? this.spinesAnimationMobile : this.spinesAnimationDesktop;
          this._animate('story'+ num);
        },

        onEnterStoryForeword: lifecycle => { this.states.handleScroll('foreword', lifecycle); },
        onEnterStoryRoKwon: lifecycle => { this.states.handleScroll('ro-kwon', lifecycle); },
        onEnterStoryOisinMckenna: lifecycle => { this.states.handleScroll('oisin-mckenna', lifecycle); },
        onEnterStoryBrontezPurnell: lifecycle => { this.states.handleScroll('brontez-purnell', lifecycle); },
        onEnterStoryIsleMcelroy: lifecycle => { this.states.handleScroll('isle-mcelroy', lifecycle); },
        onEnterStoryRoxaneGay: lifecycle => { this.states.handleScroll('roxane-gay', lifecycle); },
        onEnterStoryJohnPaulBrammer: lifecycle => { this.states.handleScroll('john-paul-brammer', lifecycle); },
        onEnterStoryInfo: lifecycle => { this.states.handleScroll('info', lifecycle); },

        /**
         * Internal/custom methods
         */
        
        handleScroll: (story, lifecycle) => {
          // If preserve scroll is set, do nothing/don't scroll and reset the variable...
          if(this.states.preserveScroll) {
            this.states.preserveScroll = false;
          }
          // If preserve scroll is not set, scroll to the story
          else {
            document.querySelector(`[data-story="${story}"]`).scrollIntoView();
            setTimeout(() => {
              this._updateStoryMobileNavInfo(document.querySelector(`[data-story="${story}"]`), true);
            }, 250);
          }

          setTimeout(() => {
            if(lifecycle.from == 'menu' && this.states)
              this.states.scrollTriggering = true;
          }, 250);

          if(Utils.isMobileView())
            document.body.focus();
          else
            document.querySelector('.stories').focus();
        }

      }
    });

    this._fillTotalPages();

    document.querySelectorAll(`[data-slideshow]`).forEach(el => {
      new GlideSlideshow(el);
    });

    const popupEl = document.querySelector('.spine-pop-up');
    document.querySelectorAll('[data-story-spine]').forEach(el => {
      const storyNum = el.getAttribute('data-story-spine');
      const storyEl = document.querySelector(`[data-story="${storyNum}"]`);

      el.addEventListener('mouseenter', e => {
        popupEl.querySelector('.spine-pop-up__title').innerHTML = storyEl.getAttribute('data-story-who');
        popupEl.querySelector('.spine-pop-up__text').innerHTML = storyEl.getAttribute('data-story-a-love-story');

        popupEl.style.opacity = 1;
      });
      el.addEventListener('mouseleave', e => popupEl.style.opacity = 0);
      el.addEventListener('mousemove', e => {
        popupEl.style.top = (e.clientY + 10) + 'px';

        let popupOverflowX = (e.clientX - 10 + popupEl.getBoundingClientRect().width + 10 ) - window.innerWidth;
        popupOverflowX = popupOverflowX > 0 ? popupOverflowX : 0;

        let popupOverflowY = (e.clientY + 10 + popupEl.getBoundingClientRect().height + 10) - window.innerHeight;
        popupOverflowY = popupOverflowY > 0 ? popupOverflowY : 0;

        popupEl.style.left = (e.clientX - 10 - popupOverflowX) + 'px';
        popupEl.style.top = (e.clientY + 10 - popupOverflowY) + 'px';
      });
    });

    gsap.registerPlugin(ScrollTrigger);

    document.querySelectorAll(`[data-page-num]`).forEach(el => {
      ScrollTrigger.create({
        scroller: document.querySelector('.stories'),
        trigger: el,
        start: 'top 50%',
        end: 'bottom 50%',
        onLeave: () => this._deactivatePage(el),
        onLeaveBack: () => this._deactivatePage(el),
        onEnter: () => this._activatePage(el),
        onEnterBack: () => this._activatePage(el),
      });
    });


    document.querySelectorAll(`[data-page-num]:not(.hide-xs-only.hide-sm-only)`).forEach(el => {
      ScrollTrigger.create({
        trigger: el,
        start: 'top-=50',
        end: 'bottom-=50',
        onEnter: () => {
          this._updateStoryMobileNavInfo(el.closest('[data-story]'), el.classList.contains('story__section--cover'));
        },
        onEnterBack: () => {
          this._updateStoryMobileNavInfo(el.closest('[data-story]'), el.classList.contains('story__section--cover'));
        },
      });
    });
  }


  _deactivatePage(pageEl) {
    pageEl.classList.remove('story__section--active');
  }

  _activatePage(pageEl) {
    pageEl.classList.add('story__section--active');

    const storyEl = pageEl.closest('[data-story]');

    this._updateCurrentPage(pageEl);
    this._updateStorySideInfo(storyEl);

    if(pageEl.classList.contains('story__section--cover')) {
      document.body.classList.add('cover--active');
    } else {
      document.body.classList.remove('cover--active');
    }

    if(pageEl.classList.contains('story__section--invert-side-info'))
      document.body.classList.add('invert-side-info--active');
    else
      document.body.classList.remove('invert-side-info--active');

    if(pageEl.classList.contains('story__section--invert-menu-icon'))
      document.body.classList.add('invert-menu-icon--active');
    else
      document.body.classList.remove('invert-menu-icon--active');
  }


  _fillTotalPages() {
    const totalSections = parseInt(document.querySelectorAll(`.story__section`).length) - 1;
    document.querySelector(`[data-total-page]`).innerHTML = totalSections < 100 ? totalSections.toString().padStart(3, '0') : totalSections;
  }

  _updateCurrentPage(currentSectionEl) {
    const sectionNumber = this._indexInClass(currentSectionEl, document.querySelectorAll('.story__section'));
    document.querySelector(`[data-current-page]`).innerHTML = parseInt(sectionNumber) < 100 ? sectionNumber.toString().padStart(3, '0') : sectionNumber;
  }

  _updateStorySideInfo(el) {
    document.querySelector(`[data-side-story-title]`).innerHTML = el.getAttribute('data-story-title');
    document.querySelector(`[data-side-story-author]`).innerHTML = el.getAttribute('data-story-author') ? `&nbsp;&nbsp;&nbsp;BY ${el.getAttribute('data-story-author')}` : '';
  }

  _updateStoryMobileNavInfo(el, isCover) {
    if(isCover) {
      document.querySelector(`[data-mobile-nav-who]`).innerHTML = '<span style="color: black;">No ordinary love</span>';
      document.querySelector(`[data-mobile-nav-author]`).innerHTML = '';
    } else {
      document.querySelector(`[data-mobile-nav-who]`).innerHTML = el.getAttribute('data-story-who') || el.getAttribute('data-story-title');
      document.querySelector(`[data-mobile-nav-author]`).innerHTML = el.getAttribute('data-story-author') ? '&nbsp;by&nbsp;' + el.getAttribute('data-story-author') : '';
    }
  }


  //HELPERS ANIMATIONS
  _animate(animation) {
    this._initAnimation(animation, 'cover');
    this._initAnimation(animation, 'story1');
    this._initAnimation(animation, 'story2');
    this._initAnimation(animation, 'story3');
    this._initAnimation(animation, 'story4');
    this._initAnimation(animation, 'story5');
    this._initAnimation(animation, 'story6');
    if(this.spineAnimations.animations[animation].footer)
      this._initAnimation(animation, 'footer');

    let rafForResizeId = undefined;
    window.addEventListener('resize', event => {
      rafForResizeId = Utils.debouncedRequestAnimationFrame(rafForResizeId, () => {
        this._initAnimation(animation, 'cover');
        this._initAnimation(animation, 'story1');
        this._initAnimation(animation, 'story2');
        this._initAnimation(animation, 'story3');
        this._initAnimation(animation, 'story4');
        this._initAnimation(animation, 'story5');
        this._initAnimation(animation, 'story6');
        if(this.spineAnimations.animations[animation].footer)
          this._initAnimation(animation, 'footer');


        this.spineAnimations.animations[animation].cover.anim.seek( this.timing );
        this.spineAnimations.animations[animation].story1.anim.seek( this.timing );
        this.spineAnimations.animations[animation].story2.anim.seek( this.timing );
        this.spineAnimations.animations[animation].story3.anim.seek( this.timing );
        this.spineAnimations.animations[animation].story4.anim.seek( this.timing );
        this.spineAnimations.animations[animation].story5.anim.seek( this.timing );
        this.spineAnimations.animations[animation].story6.anim.seek( this.timing );

        if(this.spineAnimations.animations[animation].footer)
          this.spineAnimations.animations[animation].footer.anim.seek( this.timing );
      });
    });

    this.spineAnimations.animations[animation].cover.anim.play();
    this.spineAnimations.animations[animation].story1.anim.play();
    this.spineAnimations.animations[animation].story2.anim.play();
    this.spineAnimations.animations[animation].story3.anim.play();
    this.spineAnimations.animations[animation].story4.anim.play();
    this.spineAnimations.animations[animation].story5.anim.play();
    this.spineAnimations.animations[animation].story6.anim.play();

    if(this.spineAnimations.animations[animation].footer)
      this.spineAnimations.animations[animation].footer.anim.play();
  }

  _initAnimation(animName, elementAnim) {
    this.spineAnimations.animations[animName][elementAnim].anim = anime(this.spineAnimations.animations[animName][elementAnim].params);
  }

  //HELPERS
  _indexInClass(node, collection) {
    for (let i = 0; i < collection.length; i++) {
      if (collection[i] === node)
        return i;
    }
    return -1;
  }
}
