export default class SpinesAnimationDesktop {
  constructor(options) {
    this.timing = options.timing;
    this.easing = options.easing;

    this.containerEl = document.querySelector('.menu-mobile');
    this.coverEl = this.containerEl.querySelector('.menu-mobile__book-cover');
    this.footerEl = this.containerEl.querySelector('.footer-mobile');
    this.story1El = this.containerEl.querySelector('[data-story-spine="ro-kwon"]');
    this.story2El = this.containerEl.querySelector('[data-story-spine="oisin-mckenna"]');
    this.story3El = this.containerEl.querySelector('[data-story-spine="brontez-purnell"]');
    this.story4El = this.containerEl.querySelector('[data-story-spine="isle-mcelroy"]');
    this.story5El = this.containerEl.querySelector('[data-story-spine="roxane-gay"]');
    this.story6El = this.containerEl.querySelector('[data-story-spine="john-paul-brammer"]');


    this.animations = {
      menu: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: '0',
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
              document.body.classList.remove('animation-menu-hidden');
              document.querySelectorAll(`[data-story]`).forEach(el => el.classList.remove('story--active'));
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: '0',
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: '0',
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: '0',
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: '0',
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: '0',
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: '0',
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: '0',
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      },
      story1: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -1 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
              document.body.classList.add('animation-menu-hidden');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -1 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      },
      story2: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -2 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
              document.body.classList.add('animation-menu-hidden');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -2 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -2 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      },
      story3: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -3 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
              document.body.classList.add('animation-menu-hidden');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -3 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -3 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -3 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 3 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 3 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 3 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 3 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      },
      story4: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
              document.body.classList.add('animation-menu-hidden');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -4 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 2 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 2 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 2 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      },
      story5: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
              document.body.classList.add('animation-menu-hidden');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -5 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 1 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 1 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      },
      story6: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
              document.body.classList.add('animation-menu-hidden');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * 0 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * 0 + ${this.footerEl.getBoundingClientRect().height}px * 1 + ${this.story1El.getBoundingClientRect().height}px * 0 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      },
      storyinfo: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
              document.body.classList.add('animation-menu-hidden');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      },
      storyforeword: {
        cover: {
          params: {
            targets: this.coverEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
              document.body.classList.add('animation-menu-hidden');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
        footer: {
          params: {
            targets: this.footerEl,
            translateY: () => `calc(${this.coverEl.getBoundingClientRect().height}px * -1 + ${this.footerEl.getBoundingClientRect().height}px * -1 + ${this.story1El.getBoundingClientRect().height}px * -6 )`,
            duration: this.timing,
            easing: this.easing,
            autoplay: false,
          },
          anim: undefined,
        },
      }
    };
  }
}
