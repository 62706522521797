import 'html5-boilerplate/dist/js/vendor/modernizr-3.11.2.min.js';
import 'html5-boilerplate/dist/js/plugins.js';
import 'lazysizes';
import 'simplebar';

import Utils from './helpers/utils.js';

import Router from './controllers/router.js';


Utils.docReady(() => {
  const router = new Router;
  router.resolve();

  document.documentElement.style.setProperty('--window-width', `${ document.body.offsetWidth }px`);
  document.documentElement.style.setProperty('--window-height', `${ window.innerHeight }px`);

  setTimeout(() => document.body.classList.add('doc-ready'), 200);


  const modalCookiePolEl = document.querySelector('.cookie-policy-modal');
  document.querySelector('[data-open-cookie-policy]').addEventListener('click', () => {
    modalCookiePolEl.classList.add('cookie-policy-modal--active');
  });

  document.querySelector('[data-close-cookie-policy]').addEventListener('click', () => {
    modalCookiePolEl.classList.remove('cookie-policy-modal--active');
  });

  modalCookiePolEl.addEventListener('click', () => {
    modalCookiePolEl.classList.remove('cookie-policy-modal--active');
  });

  modalCookiePolEl.querySelector('.cookie-policy-modal__content').addEventListener('click', e => e.stopPropagation());

  let lastWindowWidth = window.innerWidth;
  let rafForResizeId = undefined;

  window.addEventListener('resize', event => {
    rafForResizeId = Utils.debouncedRequestAnimationFrame(rafForResizeId, () => {
      document.documentElement.style.setProperty('--window-width', `${ document.body.offsetWidth }px`);
      document.documentElement.style.setProperty('--window-height', `${ window.innerHeight }px`);

      //Reload page when changing from mobile to desktop, or desktop to mobile
      if(Utils.isMobileView() && lastWindowWidth >= Utils.desktopBreakpoint
     || !Utils.isMobileView() && lastWindowWidth < Utils.desktopBreakpoint)
        location.reload();

      lastWindowWidth = window.innerWidth;
    });
  });
});
