import Glide from '@glidejs/glide';

class GlideSlideshow {
  constructor(el, options) {
    this.el = el;
    this.options = options;

    this.glideArrowsEl = this.el.querySelector('.glide__arrows');

    this.id = this.el.getAttribute('id');

    this.glide = undefined;

    this.countSlides = this.el.querySelectorAll('.glide__slide').length;

    this._init();
  }


  /*
   * INIT
   */
  _init() {
    this.glide = new Glide(this.el, {
      type: 'carousel',
      autoplay: this.options?.autoplay ? 5000 : false,
      animationDuration: 1000,
      keyboard: true,
      hoverpause: false,
      gap: 0,
      swipeThreshold: 30
    });

    //update active caption
    if(this.el.querySelectorAll('[data-glide-caption]').length > 0)
      this.el.querySelectorAll('[data-glide-caption]')[0].classList.add('active');

    this.glide.on('run', move => {
      //update active caption
      if(this.el.querySelectorAll('[data-glide-caption]').length > 0) {
        this.el.querySelectorAll('[data-glide-caption]').forEach(el => {
          el.classList.remove('active');
        });
        this.el.querySelectorAll('[data-glide-caption]')[this.glide.index].classList.add('active');
      }

      //update counter
      if(this.el.querySelector('[data-glide-counter]'))
        this.el.querySelector('[data-glide-counter] [data-glide-current]').innerHTML = (this.glide.index +1);
    });

    //update counter
    if(this.el.querySelector('[data-glide-counter]')) {
      this.el.querySelector('[data-glide-counter] [data-glide-current]').innerHTML = 1;
      this.el.querySelector('[data-glide-counter] [data-glide-total]').innerHTML = this.countSlides;
    }

    if(this.countSlides > 1) {
      //init/mount slide
      this.glide.mount();
    }
  }


  /*
   * Destroy
   */
  destroy() {
    this.glide.destroy();
  }
};
export default GlideSlideshow;
