import Navigo from 'navigo';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Utils from '../helpers/utils.js';

import StoriesController from './stories.js';


export default class Router {
  constructor(options) {
    this.navigo = new Navigo('/');

    this.storiesController = new StoriesController();

    this.historyList = [];

    this.navigo.hooks({
      after: (match) => this.historyList.push(match)
    });

    this._setupRoutes();

    //links data-navigo - Stories
    document.querySelectorAll('[data-navigo]').forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault();

        this.navigo.navigate(el.href.split(window.origin).pop());
      });
    });

    //hamb / x icon
    document.querySelectorAll('[data-menu-icon]').forEach(el => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        
        if(this.storiesController.states.state == 'menu') {
          this.lastStoryName = this.historyList.length > 1 ? this.historyList[ this.historyList.length - 2 ].url.split('story/').pop() : 'foreword';

          this.storiesController.states.preserveScroll = true;

          this.navigo.navigate('/story/' + this.lastStoryName);
        }
        else {
          this.navigo.navigate('/');
        }
      });
    });

    if (history.scrollRestoration)
      history.scrollRestoration = 'manual';

    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.clearScrollMemory('manual');

    document.querySelectorAll(`[data-story]`).forEach(el => {
      ScrollTrigger.create({
        scroller: document.querySelector('.stories'),
        trigger: el,
        start: 'top 50%',
        end: 'bottom 50%',
        onEnter: () => {
          if(Utils.isMobileView())
            return;

          if(this.storiesController.states?.scrollTriggering) {
            if(this.navigo.current[0].url != 'story/'+ el.getAttribute('data-story') ) {
              this.storiesController.states.preserveScroll = true;
              this.navigo.navigate('/story/' + el.getAttribute('data-story'));
            }
          }
        },
        onEnterBack: () => {
          if(Utils.isMobileView())
            return;

          if(this.storiesController.states?.scrollTriggering) {
            if(this.navigo.current[0].url != 'story/'+ el.getAttribute('data-story') ) {
              this.storiesController.states.preserveScroll = true;
              this.navigo.navigate('/story/' + el.getAttribute('data-story'));
            }
          }
        },
      });

      ScrollTrigger.create({
        trigger: el,
        start: 'top-=50',
        end: 'bottom-=50',
        onEnter: () => {
          if(!Utils.isMobileView())
            return;

          if(this.storiesController.states?.scrollTriggering) {
            if(this.navigo.current[0].url != 'story/'+ el.getAttribute('data-story') ) {
              this.storiesController.states.preserveScroll = true;
              this.navigo.navigate('/story/' + el.getAttribute('data-story'));
            }
          }
        },
        onEnterBack: () => {
          if(!Utils.isMobileView())
            return;

          if(this.storiesController.states?.scrollTriggering) {
            if(this.navigo.current[0].url != 'story/'+ el.getAttribute('data-story') ) {
              this.storiesController.states.preserveScroll = true;
              this.navigo.navigate('/story/' + el.getAttribute('data-story'));
            }
          }
        },
      });
    });
  }

  _setupRoutes() {
    this.navigo.on('/', params => {
      this.storiesController.states.goToMenu();
    });

    this.navigo.on('/story/:num', params => {
      this.storiesController.states['goToStory'+ this._capitalizeFirstLetter( params.data.num.replace(/-./g, c => c. substring(1).toUpperCase()) ) ]();
    });
  }

  resolve() {
    this.navigo.resolve();
  }

  _capitalizeFirstLetter(string) {
    return string.replace(/^\w/, (c) => c.toUpperCase());
  }
}
