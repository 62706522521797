export default class SpinesAnimationDesktop {
  constructor(options) {
    this.timing = options.timing;
    this.easing = options.easing;

    this.containerEl = document.querySelector('.menu');
    this.coverEl = this.containerEl.querySelector('.menu__book-cover');
    this.story1El = this.containerEl.querySelector('[data-story-spine="ro-kwon"]');
    this.story2El = this.containerEl.querySelector('[data-story-spine="oisin-mckenna"]');
    this.story3El = this.containerEl.querySelector('[data-story-spine="brontez-purnell"]');
    this.story4El = this.containerEl.querySelector('[data-story-spine="isle-mcelroy"]');
    this.story5El = this.containerEl.querySelector('[data-story-spine="roxane-gay"]');
    this.story6El = this.containerEl.querySelector('[data-story-spine="john-paul-brammer"]');

    this.animations = {
      menu: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: '0',
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
              document.querySelectorAll(`[data-story]`).forEach(el => el.classList.remove('story--active'));
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: '0',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: '0',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: '0',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: '0',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: '0',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: '0',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      },
      storyforeword: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: '-50vw',
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      },
      story1: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 1) * -1 )'],
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 1) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      },
      story2: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 2) * -1 )'],
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 2) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 2) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      },
      story3: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 3) * -1 )'],
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 3) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 3) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 3) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      },
      story4: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 4) * -1 )'],
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 4) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 4) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 4) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 4) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      },
      story5: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 5) * -1 )'],
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 5) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 5) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 5) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 5) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 5) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: '50vw',
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      },
      story6: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      },
      storyinfo: {
        cover: {
          params: {
            targets: this.coverEl,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
            begin: () => {
              document.body.classList.add('is-animating');
            },
            complete: () => {
              document.body.classList.remove('is-animating');
            }
          },
          anim: undefined,
        },
        story1: {
          params: {
            targets: this.story1El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story2: {
          params: {
            targets: this.story2El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story3: {
          params: {
            targets: this.story3El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story4: {
          params: {
            targets: this.story4El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story5: {
          params: {
            targets: this.story5El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
        story6: {
          params: {
            targets: this.story6El,
            translateX: ['calc((50vw + 100vw/12) * 0 )', 'calc((50vw + 100vw/12 * 6) * -1 )'],
            duration: this.timing,
            easing: this.easing,
          },
          anim: undefined,
        },
      }
    };

  }

}
